<template>
  <div>
    <b-form @submit="onSubmit">
      <b-row>
        <b-col cols="12" md="4">
          <b-img fluid-grow thumbnail v-if="materiel.photo" :src="materiel.photo.url"></b-img>
          <b-icon-card-image v-else style="width:100%; height:200px"></b-icon-card-image>
          <b-form-file
              v-model="staged_file_photo"
              placeholder="Attach an photo of this materiel"
              drop-placeholder="Drop file here..."
              accept="image/*"
              @input="uploadImage()"
          ></b-form-file>

        </b-col>
        <b-col cols="12" md="8" style="padding-top:10px">
          <b-form-group label="Manufacturer" label-cols-md="2" label-align-md="right" content-cols-md="10">
            <b-form-input v-model="materiel.manufacturer"></b-form-input>
          </b-form-group>
          <b-form-group label="Model" label-cols-md="2" label-align-md="right" content-cols-md="10">
            <b-form-input v-model="materiel.model"></b-form-input>
          </b-form-group>
          <b-form-group label="Short Description" label-cols-md="2" label-align-md="right" content-cols-md="10">
            <b-form-input v-model="materiel.description"></b-form-input>
          </b-form-group>
          <b-form-group label="Serial Number" label-cols-md="2" label-align-md="right" content-cols-md="10">
            <b-form-input v-model="materiel.serial_number"></b-form-input>
          </b-form-group>
          <b-form-group label="Inventory Tag" label-cols-md="2" label-align-md="right" content-cols-md="10">
            <b-form-input v-model="materiel.inventory_tag_id"></b-form-input>
          </b-form-group>
          <b-form-group label="Website" label-cols-md="2" label-align-md="right" content-cols-md="10">
            <b-form-input v-model="materiel.url"></b-form-input>
          </b-form-group>
          <b-form-group label="Date Received" label-cols-md="2" label-align-md="right" content-cols-md="10">
            <b-form-datepicker v-model="materiel.date_received" reset-button></b-form-datepicker>
          </b-form-group>
          <b-form-group label="Owner" label-cols-md="2" label-align-md="right" content-cols-md="10">
            <b-form-input v-model="materiel.owner"></b-form-input>
          </b-form-group>
          <b-form-group label="Location" label-cols-md="2" label-align-md="right" content-cols-md="10">
            <b-form-input v-model="materiel.location"></b-form-input>
          </b-form-group>
          <b-form-group v-if="materiel.datasheet === null" label="Datasheet" label-cols-md="2" label-align-md="right" content-cols-md="10">
            <b-form-file
                v-model="staged_file_datasheet"
                placeholder="Attach a datasheet for this equipment"
                drop-placeholder="Drop file here..."
                accept=".pdf, .txt"
                @input="uploadDatasheet()"
            ></b-form-file>
          </b-form-group>
          <b-form-group v-else label="Datasheet" label-cols-md="2" label-align-md="right" content-cols-md="10">
            <b-link :href="materiel.datasheet.url" target="_blank" style="padding-right:10px">{{formatFilename(materiel.datasheet.original_filename)}}</b-link>
            <b-button variant="danger" size="sm" @click="deleteDatasheet"><b-icon-trash></b-icon-trash></b-button>
          </b-form-group>
          <b-form-group v-if="materiel.manual === null" label="Manual" label-cols-md="2" label-align-md="right" content-cols-md="10">
            <b-form-file
                v-model="staged_file_manual"
                placeholder="Attach a manual for this equipment"
                drop-placeholder="Drop file here..."
                accept=".pdf, .txt"
                @input="uploadManual()"
            ></b-form-file>
          </b-form-group>
          <b-form-group v-else label="Manual" label-cols-md="2" label-align-md="right" content-cols-md="10">
            <b-link :href="materiel.manual.url" target="_blank" style="padding-right:10px">{{formatFilename(materiel.manual.original_filename)}}</b-link>
            <b-button variant="danger" size="sm" @click="deleteManual"><b-icon-trash></b-icon-trash></b-button>
          </b-form-group>
          <b-form-group label="Archived" label-cols-md="2" label-align-md="right" content-cols-md="10">
            <b-form-checkbox v-model="materiel.archived" size="lg"></b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group label="Notes" label-cols-md="1" label-align-md="right" content-cols-md="11">
        <b-form-textarea v-model="materiel.notes"></b-form-textarea>
      </b-form-group>
      <b-button type="submit" variant="primary">Save Changes</b-button>
      <b-button variant="secondary" @click="$router.push('/materiel')">Cancel</b-button>
    </b-form>
  </div>
</template>

<script>
import {toInteger} from "lodash";
import http from "../http-common"
const _ = require('lodash');

export default {
  name: "EditMateriel",
  data: function () {
    return {
      materiel: {
        id: null,
        archived: false,
        photo: {url: ""},
        datasheet: {url: "", original_filename: ""},
        manual: {url: "", original_filename: ""},
        manufacturer: "",
        model: "",
        serial_number: "",
        url: "",
        date_received: null,
        owner: "",
        location: "",
        notes: "",
        supply_id: "",
        supply_quantity: 1,
        inventory_tag_id: "",
        requisition_id: null,
        vendor: "",
      },
      staged_file_photo: null,
      staged_file_datasheet: null,
      staged_file_manual: null,
    };
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      http.put(`materiel/${this.materiel.id}`, this.materiel)
          .then(() => {
            this.$router.push('/materiel');
          })
          .catch((err) => {
            http.std_error_handler(this, err);
          });
    },
    uploadImage() {
      const loader = this.$loading.show();
      http.upload_files([this.staged_file_photo],
          (res_id) => {
            return http.put(`materiel/${this.materiel.id}/update-photo`,
                {}, {params: {resource_id: res_id}})
                .catch((err) => {
                  http.std_error_handler(this, err);
                });
          },
          () => {
            http.get(`materiel/${this.materiel.id}`)
                .then((resp) => {
                  this.materiel.photo = resp.data.photo;
                })
                .finally(() => {
                  this.staged_file_photo = null;
                  loader.hide();
                });
          }
      );
    },
    uploadDatasheet() {
      const loader = this.$loading.show();
      http.upload_files([this.staged_file_datasheet],
          (res_id) => {
            return http.put(`materiel/${this.materiel.id}/update-datasheet`,
                {}, {params: {resource_id: res_id}})
                .catch((err) => {
                  http.std_error_handler(this, err);
                });
          },
          () => {
            http.get(`materiel/${this.materiel.id}`)
                .then((resp) => {
                  this.materiel.datasheet = resp.data.datasheet;
                })
                .finally(() => {
                  this.staged_file_datasheet = null;
                  loader.hide();
                });
          }
      );
    },
    deleteDatasheet() {
      http.put(`materiel/${this.materiel.id}/remove-datasheet`)
          .then(() => {
            this.materiel.datasheet = null
          })
    },
    formatFilename(fname) {
      return _.truncate(fname);
    },
    uploadManual() {
      const loader = this.$loading.show();
      http.upload_files([this.staged_file_manual],
          (res_id) => {
            return http.put(`materiel/${this.materiel.id}/update-manual`,
                {}, {params: {resource_id: res_id}})
                .catch((err) => {
                  http.std_error_handler(this, err);
                });
          },
          () => {
            http.get(`materiel/${this.materiel.id}`)
                .then((resp) => {
                  this.materiel.manual = resp.data.manual;
                })
                .finally(() => {
                  this.staged_file_manual = null;
                  loader.hide();
                });
          }
      );
    },
    deleteManual() {
      http.put(`materiel/${this.materiel.id}/remove-manual`)
          .then(() => {
            this.materiel.manual = null
          })
    },
  },
  beforeMount() {
    let loader = this.$loading.show();
    let id = toInteger(this.$route.params.id);
    http.get(`/materiel/${id}`)
        .then((resp) => {
          Object.assign(this.materiel, resp.data)
          loader.hide();
        })

  },
}
</script>

<style scoped>

</style>